import { STORE_TYPE } from "constants/route";
import {apiCall, paramsToString} from "utils/api";
import {sellerStoreId} from 'utils/auth';
import { getDevicePlatform } from "utils/misc";

const HOST = `${process.env.NEXT_PUBLIC_API_HOST}/OTP`;

export const sendOtpV2 = async (mobileNumber: string) => {
  const res = await apiCall({
    method: 'post',
    url: `${HOST}/welcome/v2?isFourDigit=true`,
    headers: {
      'phone': mobileNumber
    }
  })
  return res.data;
}

export const confirmOtp = async (authToken: string, otp: string, utmSource: string, storeType: STORE_TYPE, isB2bUpgrade = false) => {
  const res = await apiCall({
    method: 'post',
    url: `${HOST}/confirmation?${paramsToString({
      utmSource,
      ...(isB2bUpgrade ? { isUpgrade: isB2bUpgrade } : {})
    })}`,
    headers: {
      'x-auth-token': authToken,
      'otp': otp,
      'X-Device-Type': getDevicePlatform(),
      'x-store-type': storeType,
    }
  })
  return {data:res.data,headers: res.headers} ;
}

export const bankDetailsUpdateSendOtp = async (resendOTP: boolean = false) => {
  const res = await apiCall({
    method: 'post',
    url: `${HOST}/${sellerStoreId()}/cloutBankUpdate` + (resendOTP ? '/resendOTP' : ''),
  });

  return res.data;
};

export const bankDetailsUpdateVerifyOtp = async (OTP: number | string) => {
  const res = await apiCall({
    method: 'post',
    url: `${HOST}/${sellerStoreId()}/cloutBankUpdate/authenticate?otp=${OTP}`,
  });

  return res.data;
};
