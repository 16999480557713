import { useEffect, useRef, useState } from "react";
import { useRouter } from "next/router";
import { getAllowedEndpointsForRoute } from "utils/misc";
import rnaAnalytics from 'services/analytics/rnaAnalytics';
import { EventProperty } from 'services/analytics/rnaAnalytics/constants';
import { AnalyticsEventAction } from 'constants/analytics';

type MetricType = {
  pageLoadTime: number | null;
  apiRequestTime: number | null;
  pathName: string | null;
};

const sendMetricsToAnalytics = (metrics: MetricType) => {
  rnaAnalytics.feedbackEvent({
    action: AnalyticsEventAction.PAGE_LOAD_METRICS,
    eventData: { [EventProperty.Extras]: metrics },
  });
};

const isPerformanceResourceTiming = (entry: PerformanceEntry): boolean => {
  return entry.entryType === "resource" && "initiatorType" in entry && "name" in entry;
};

const PageLoadAndAPITracking = () => {
  const router = useRouter();
  const [pageLoadTime, setPageLoadTime] = useState<number | null>(null);
  const [apiRequestTime, setApiRequestTime] = useState<number | null>(null);
  // eslint-disable-next-line no-undef
  const trackedUrls = useRef<Set<string>>(new Set());
  const observerRef = useRef<PerformanceObserver | null>(null);
  const routeChangeStartTime = useRef<number | null>(null);
  const isFirstLoad = useRef(true);
  const xhrTimings = useRef<{ startTime: number; endTime: number }[]>([]);
  const [hasMetricsBeenSent, setHasMetricsBeenSent] = useState(false);

  const handleTracking = (pathname: string) => {
    const allowedEndpoints = getAllowedEndpointsForRoute(pathname) || [];
    setApiRequestTime(null);
    trackedUrls.current.clear();
    xhrTimings.current = []; // Reset timings for the new route

    if (observerRef.current) {
      observerRef.current.disconnect();
    }

    if (!allowedEndpoints.length) {
      setApiRequestTime(0);
      return;
    }

    observerRef.current = new PerformanceObserver((list) => {
      const resourceEntries = list.getEntriesByType("resource");
      const xhrCalls = resourceEntries.filter(
        (entry: any) =>
          isPerformanceResourceTiming(entry) &&
          entry.initiatorType === "xmlhttprequest" &&
          allowedEndpoints.some((endpoint) => entry.name.includes(endpoint)) &&
          !trackedUrls.current.has(entry.name)
      );

      xhrCalls.forEach((entry: any) => {
        trackedUrls.current.add(entry.name);
        xhrTimings.current.push({ startTime: entry.startTime, endTime: entry.responseEnd });
      });

      // Capture maximum API time among current requests
      if (xhrTimings.current.length > 0) {
        const maxApiTime = Math.max(...xhrTimings.current.map((t) => t.endTime - t.startTime));
        setApiRequestTime(maxApiTime);
      }
    });

    observerRef.current.observe({ entryTypes: ["resource"] });
  };

  useEffect(() => {
    const handleIntialPageLoad = () => {
      const [navigationEntry] = performance.getEntriesByType("navigation");
      if (navigationEntry && navigationEntry instanceof PerformanceNavigationTiming) {
        const loadTime = (navigationEntry.loadEventEnd || navigationEntry.domComplete) - navigationEntry.startTime;
        setPageLoadTime(loadTime);
        isFirstLoad.current = false;
      }
    };

    if (isFirstLoad.current) {
      routeChangeStartTime.current = performance.now();
      handleTracking(router.pathname);

      if (document.readyState === "complete") {
        handleIntialPageLoad();
      } else {
        window.addEventListener("load", handleIntialPageLoad);
        return () => window.removeEventListener("load", handleIntialPageLoad);
      }
    }

    return () => observerRef.current?.disconnect();
  }, []);

  useEffect(() => {
    const handleRouteChangeStart = () => {
      setPageLoadTime(null);
      setApiRequestTime(null);
      trackedUrls.current.clear();
      routeChangeStartTime.current = performance.now();
      observerRef.current?.disconnect(); // Clean up the observer on route change
    };

    const handleRouteChangeComplete = (url: string) => {
      const loadTime = performance.now() - (routeChangeStartTime.current || 0);
      setPageLoadTime(loadTime);
      handleTracking(url); // Track the API requests for the new page
    };

    router.events.on("routeChangeStart", handleRouteChangeStart);
    router.events.on("routeChangeComplete", handleRouteChangeComplete);

    return () => {
      router.events.off("routeChangeStart", handleRouteChangeStart);
      router.events.off("routeChangeComplete", handleRouteChangeComplete);
    };
  }, [router.events]);

  // Send metrics to analytics after the data is gathered
  useEffect(() => {
    if (pageLoadTime !== null && apiRequestTime !== null && !hasMetricsBeenSent) {
      sendMetricsToAnalytics({ pageLoadTime, apiRequestTime, pathName: router.pathname });
      setHasMetricsBeenSent(true); // Prevent duplicate metric sends
    }
  }, [pageLoadTime, apiRequestTime, hasMetricsBeenSent]);

  // Reset metrics on route change start
  useEffect(() => {
    const handleRouteChangeStart = () => { setHasMetricsBeenSent(false); }; // Allow metrics to be sent for the new page

    router.events.on("routeChangeStart", handleRouteChangeStart);
    return () => { router.events.off("routeChangeStart", handleRouteChangeStart); };
  }, [router.events]);

  return null; // This is a non-UI component
};

export default PageLoadAndAPITracking;
