export enum PAGES_ENUM {
  HOME = "/",
  MANAGE_ORDER = "/order/manage/",
  MANAGE_NDR = "/manage-ndr/",
  COLLECTION = "/collection",
  CATEGORY = "/category",
  ANALYTICS = "/dashboard/analytics",
  PUSHED_TO_SHOPIFY = "/manage-products/pushedToShopify",
  INVENTORY_REQUESTS = "/manage-products/inventoryRequests",
  SOURCE_PRODUCT = "/source-product",
  REPORTS = "/reports",
  PAYMENTS = "/payments",
  WISHLIST = "/wishlist",
  WHATSAPP = "/dashboard/whatsapp",
  CLOUT_UPDATES = "/clout-updates",
  PROFILE = "/profile",
  STORE_MANAGER = "/store-manager",
  STAFF_ACCESS_MANAGEMENT = "/staff-access-management",
}

export enum STORE_TYPE {
  B2B = "b2b",
  B2C = "marketplace",
}

export type StoreType = STORE_TYPE.B2B | STORE_TYPE.B2C;
export const signUpSteps =  {
  BUSINESS_AGREEMENT: "BusinessAgreementPending",
  GST_DETAILS: "ValidGSTPending",
}
export const storeType = {
  [STORE_TYPE.B2B]: {
    value: STORE_TYPE.B2B,
    urlPrefix: 'b2b',
  },
  [STORE_TYPE.B2C]: {
    value: STORE_TYPE.B2C,
    urlPrefix: 'marketplace',
  }
}

export const storeTypeValues = Object.values(storeType).map((store) => store.value);
export const storeTypeUrlPrefixList = Object.values(storeType).map((store) => store.urlPrefix);
