import {confirmOtp as confirmOtpApi, sendOtpV2 as sendOtpApi} from "data/otp";
import { confirmLogout as confirmLogoutApi } from "data/logout";
import {COOKIES, getCookie, setCookie} from "utils/cookies";
import {getPathNameWithStoreType, getState, redirect} from "utils/misc";
import {NextRouter} from "next/router";
import { toast } from "react-toastify";
import {logoutStaff} from "../data/saas";
import {ADMIN_SESSION_EXPIRY_TIME} from "../constants/expiry";
import { getStoreType, isInvestorStore, isStaff, isUserLoggedIn, userOnboardingStatus } from "utils/auth";
import { getStoreTypeDetails } from "data/shopify";
import {signUpSteps, STORE_TYPE} from "constants/route";

export const handleSendOTP = async (phoneNumber: string, setAuthToken: Function, setPageIndex: Function, startResendCount: Function, setIsLoading: Function) => {
  try {
    if (phoneNumber.length === 10) {
      // call otp api
      setIsLoading(true);
      const sendOtpResponse = await sendOtpApi(phoneNumber);
      setIsLoading(false);
      setAuthToken(sendOtpResponse.authToken);
      setPageIndex(1);
      startResendCount();
    }
  } catch (error) {
    setIsLoading(false);
    const e = error.response? error.response.data.status :"Something went wrong";
    toast.error(e);
  }
}

export const handleAdminSuccessFullLoginRedirect = (router: NextRouter) => {
  const {code, shop, state, hmac, client_id} = getState(router);

  const onboardingStatus = userOnboardingStatus();

  if(onboardingStatus.includes(signUpSteps.BUSINESS_AGREEMENT)){
    redirect({
      pathname: '/b2b/signup-step/business-agreement',
      query: getState(router),
    });
  } else if (onboardingStatus.includes(signUpSteps.GST_DETAILS)){
    redirect({
      pathname: '/b2b/signup-step/gst-details',
      query: getState(router),
    });
  } else if (code && shop && state && hmac) {
    redirect({
      pathname: '/link-shopify',
      query: getState(router),
    });
  } else if (client_id) {
    redirect({
      pathname: '/freshdesk/login',
      query: getState(router),
    });
  } else {
    redirect({
      pathname: getState(router).redirect_uri ? decodeURIComponent(getState(router).redirect_uri) : isInvestorStore() ? getPathNameWithStoreType('/dashboard/analytics/overall') : getPathNameWithStoreType('/'),
    });
  }
}

export const handleVerifyOTP = async (OTP: string, authToken: string, router: NextRouter, setIsLoading: Function, storeType: STORE_TYPE, isB2bUpgrade: boolean) => {
  if (OTP.length === 4) {
    try {
      const utmSource = new URLSearchParams(window.location.search).get('utm_source') || '';
      // call otp api
      setIsLoading(true);
      const {data:verificationResponse, headers} = await confirmOtpApi(authToken, OTP, utmSource, storeType, isB2bUpgrade);
      const xSessionId=headers[COOKIES.xSessionId];
      if (verificationResponse.authToken && xSessionId) {
        delete verificationResponse.welcomeVideoLanguage;
        setCookie(null, COOKIES.recSession, JSON.stringify(verificationResponse), null, 30);
        setCookie(null, COOKIES.xSessionId, xSessionId, null, ADMIN_SESSION_EXPIRY_TIME  );

        handleAdminSuccessFullLoginRedirect(router);
      }
    } catch (e) {
      setIsLoading(false);
      if(e.response?.status === 400){
        return {
          error: e.response?.data?.status,
          status: 400
        };
      }
      toast.error(e.response?.data?.status || "Something went wrong");
    }
  }
}

export const sessionLogout = async (onSuccess?: Function) => {
  try {
    await confirmLogoutApi();
    onSuccess?.();
  } catch (err) {
    toast.error(err?.response?.data?.status);
    console.log(err);
  }
};

export const staffSessionLogout = async (onSuccess: Function, email: string) => {
  try {
    await logoutStaff(email);
    onSuccess?.();
  } catch (err) {
    toast.error(err?.response?.data?.status);
    console.log(err);
  }
};

const updateStoreTypeInUserSessionCookie = async (sessionData: any, cookieName: string) => {
  if (!sessionData || !cookieName) return;

  try {
    const storeTypeDetails = await getStoreTypeDetails();

    if (storeTypeDetails) {
      const { onboardingStatus, storeType } = storeTypeDetails;

      if (onboardingStatus && cookieName !== COOKIES.staffConfig) sessionData.onboardingStatus = onboardingStatus;
      if (storeType) sessionData.storeType = storeType;

      setCookie(null, cookieName, JSON.stringify(sessionData), null, 30);
    }
  } catch (err) {
    console.log(err);
  }
}

export const handleUserStoreTypeUpdate = () => {
  const storeType = getStoreType();
  if (storeType) return;

  const updateSessionCookie = (cookieName: string) => {
    const cookieData = getCookie(null, cookieName);
    const sessionData = JSON.parse(cookieData || '{}');
    updateStoreTypeInUserSessionCookie(sessionData, cookieName);
  };

  if (isUserLoggedIn()) updateSessionCookie(COOKIES.recSession);
  else if (isStaff()) updateSessionCookie(COOKIES.staffConfig);
}
