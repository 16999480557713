import { apiCall, paramsToString } from "utils/api";
import {NextPageContext} from "next";
import getBtoa from "../utils/getbtoa";
import { sellerStoreId } from "utils/auth";
import { encryptStaffData } from "handlers/handleStaffDataEncryption";
const HOST = `${process.env.NEXT_PUBLIC_API_HOST}/saas`;

export interface ICloutUserRegistrationRequest {
  requesterId?: string; // store ID of Admin DS
  name?: string; // Name of staff user
  email?: string; // Staff user's email
  roles?: string[]; // List of roles for staff user
  step?: number; // STEP (type as appropriate, e.g., number, string, etc.)
  verificationCode?: string;
  password?: string;
  confirmPassword?: string;
}
export const getStaffList = async () => {
  const response = await apiCall({
    method: 'get',
    url: `${HOST}/auth/ownership?name=staff-user`,
  });
  return response.data;
}
export const createStaff = async (cloutUserRegistrationRequest: ICloutUserRegistrationRequest) => {
  const cypherText = await encryptStaffData(cloutUserRegistrationRequest);

  const response = await apiCall({
    method: 'put',
    url: `${HOST}/auth/staff`,
    data: {
      ...cypherText
    }
  });
  return response.data;
}
export const validateStaff = async ({email}:{email: string;}) => {
  const cypherText = await encryptStaffData({
    email,
    step: 'VALIDATE'
  });

  // eslint-disable-next-line no-useless-catch
  try {
    const response = await apiCall({
      method: 'put',
      url: `${HOST}/auth/staff`,
      data: {
        ...cypherText
      }
    });
    return response.data;
  }
  catch (e) {
      throw e;
  }

}

export const initiateStaff = async (data: ICloutUserRegistrationRequest) => {
  const cypherText = await encryptStaffData({
    ...data,
    step: 'INITIATE'
  });

  const response = await apiCall({
    method: 'put',
    url: `${HOST}/auth/staff`,
    data: {
      ...cypherText
    }
  });
  return response.data;
}

export const modifyStaff = async (data: ICloutUserRegistrationRequest) => {
  const cypherText = await encryptStaffData({ ...data });

  const response = await apiCall({
    method: 'post',
    url: `${HOST}/auth/staff`,
    data: {
      ...cypherText,
    }
  });
  return response.data;
}
export const deleteStaff = async (email: string) => {
  const cypherText = await encryptStaffData({ email });

  const response = await apiCall({
    method: 'delete',
    url: `${HOST}/auth/staff`,
    data: {
      ...cypherText,
    }
  });
  return response.data;
}



export const validateLink = async ({ctx, verificationCode, email} : {ctx: NextPageContext | null, verificationCode:  string |  any, email:  string |  any}) => {
  const cypherText = await encryptStaffData({
    verificationCode,
    email,
    step: 'VERIFY'
  });

  const headers = {
    'content-type': 'application/json'
  }
  const res = await apiCall({
    ctx,
    method: 'put',
    headers,
    url: `${HOST}/auth/staff`,
    data: {
      ...cypherText
    }
  })
  console.log("VERIFY", res.data)
  return res.data;
}

export const completeRegistration = async (data: ICloutUserRegistrationRequest) => {
  const cypherText = await encryptStaffData({
    ...data,
    step: 'COMPLETE'
  });

  const headers = {
    'content-type': 'application/json'
  }
  const response =  await apiCall({
    method: 'put',
    headers,
    url: `${HOST}/auth/staff`,
    data: {
      ...cypherText
    }
  })
  return  response.data;


  // console.log("COMPLETE", res.data)

}
export const regenerateLink = async (email:string | any) => {
  const cypherText = await encryptStaffData({
    email,
    step: 'RE_GENERATE_LINK'
  });

  const headers = {
    'content-type': 'application/json'
  }
  const response =  await apiCall({
    method: 'put',
    headers,
    url: `${HOST}/auth/staff`,
    data: {
      ...cypherText
    }
  })
  return  response.data;


  // console.log("COMPLETE", res.data)

}


export const getStaffDetails = async (email:string) => {
  const res = await apiCall({
    method: 'get',
    url: `${HOST}/auth/staff?userName=${email}`,
  })
  return res.data;
}
export const checkIfEmailExists = async (email:string) => {
  const headers = {
    'content-type': 'application/json',
  }
  const res = await apiCall({
    method: 'post',
    headers,
    url: `${HOST}/auth/email/validate`,
    data:{
      email:email
    }
  })

  return res.data;
}

export const loginAsStaff = async ({email,password,storeType}:{email:string, password:string, storeType?: string}) => {
  const authorization = getBtoa(`${email}:${password}`);
  const headers = {
    authorization: "Basic "+ authorization,
    authorizationScheme: 'Clout-Staff-Auth',
    'content-type': 'application/json',
    'x-store-type': storeType,
  }
  return  await apiCall({
    method: 'put',
    headers,
    url: `${HOST}/auth/me`,
  });
}

export const sendForgotPasswordLink = async (email:string) => {
  const cypherText = await encryptStaffData({
    email,
    step: 'FORGET_PASSWORD'
  });

  const response = await apiCall({
    method: 'put',
    url: `${HOST}/auth/staff`,
    data: {
      ...cypherText
    }
  });
  return response.data;
}
export const logoutStaff = async (email:string) => {
  const response = await apiCall({
    method: 'delete',
    url: `${HOST}/auth/me`,
    data: {
      email,
    }
  });
  return response.data;
}

export const getWishlistApi = async ({ nextRank, limit = 10 }: {nextRank?: number; limit?: number}) => {
  const response = await apiCall({
    method: 'get',
    url: `${HOST}/wishlist/${sellerStoreId()}?${paramsToString({
      limit,
      ...(nextRank && { nextRank }),
    })}`,
  });

  return response.data;
};

export const getWishlistVideosApi = async ({ nextRank, limit = 10 }: {nextRank?: number; limit?: number}) => {
  const response = await apiCall({
    method: 'get',
    url: `${HOST}/wishlist/videoFeed/${sellerStoreId()}?${paramsToString({
      limit,
      ...(nextRank && { nextRank }),
    })}`,
  });

  return response.data;
};

export const addToWishlistApi = async (productId: number) => {
  const response = await apiCall({
    method: 'post',
    url: `${HOST}/wishlist/${sellerStoreId()}?${paramsToString({
      productId
    })}`,
  });

  return response.data;
};

export const removeFromWishlistApi = async (productId: number) => {
  const response = await apiCall({
    method: 'delete',
    url: `${HOST}/wishlist/${sellerStoreId()}?${paramsToString({
      productId
    })}`,
  });

  return response.data;
};

export const getPriceDetailsForProduct = async (productId: number, formData: FormData) => {
  const response = await apiCall({
    method: 'post',
    url: `${HOST}/pricingCalculator/${productId}`,
    data: formData,
  });

  return response.data;
};

export const b2bUserAcceptAgreement = async ({ tncId }: { tncId: number }) => {
  const response = await apiCall({
    method: 'post',
    url: `${HOST}/stores/b2b/accept-agreement?storeId=${sellerStoreId()}&tncId=${tncId}`,
  });

  return response.data;
};